import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../pages/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Auth/SignIn.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/Auth/SignUp.vue')
  },
  {
    path: '/restore/:id/:token',
    name: 'restore',
    component: () => import('../pages/Auth/ResetPassword.vue')
  },
  {
    path: '/restore-request',
    name: 'restore-request',
    component: () => import('../pages/Auth/ResetPasswordRequest.vue')
  },
  {
    path: '/confirm/:id/:token',
    name: 'confirm',
    component: () => import('../pages/Auth/Confirm.vue')
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../pages/App/Index.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/project/:id',
    name: 'app-project',
    component: () => import('../pages/App/Project.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/view/:id',
    name: 'app-view',
    component: () => import('../pages/App/View.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
  {
    path: '/app/freedom/:id',
    name: 'app-freedom',
    component: () => import('../pages/App/Freedom.vue'),
    meta:{
        layout: "cp-layout"
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
