

import { createApp } from 'vue'
import App from './App.vue'

import { registerPlugins } from '@/plugins'

import DefaultLayout from "@/layouts/default.vue"
import CpLayout from "@/layouts/cp.vue"

// import '@/assets/scss/variables.scss'

//И зарегистрируем их в нашем приложении

const app = createApp(App);

app.component("default-layout", DefaultLayout)
app.component("cp-layout", CpLayout)


registerPlugins(app);
app.mount('#app')


