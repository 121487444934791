<template>
  <v-app full-height class="w-100">
      <component :is="layout">
          <router-view/>
      </component>
    </v-app>
</template>
<script>

export default {
  name: 'App',
  computed: {
    layout(){
        return this.$route.meta.layout || "default-layout" 
    }
  }
}


// Vue js Sockets Я хочу сделать чат на основе сокета как это сделать во vue js в pinia есть методы: sendMessage, newMessage, getChat, getChats, createChat
</script>

<style lang="scss">

</style>
