let config
if (process.env.NODE_ENV == 'development') {
  config={
    url: 'https://id.ao-nk.ru/oauth',

    params: {
      // ид клиента
      clientId: '2536fd68-a54e-445b-a5ef-7cdb26f79703',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar'],
    }
  }
} else {
  config={
    url: 'https://id.ao-nk.ru/oauth',

    params: {
      // ид клиента
      clientId: '2536fd68-a54e-445b-a5ef-7cdb26f79703',
      // требуемые разрешения (АО НК может отдать меньше разрешений, но не больше чем было указно в настроках клиента)
      scopes: [
        'name',
        'surname',
        'patronymic',
        'email',
        'avatar'],
    }
  }
}

module.exports = config
