// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@/styles/generic/_reset.scss'

// Module not found: Error: Can't resolve '@mdi/font/css/materialdesignicons.css' in '/Users/ilabycek/Desktop/Projects/mindMap/frontend/mindmap/src/plugins'


// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    theme: {
      defaultTheme: 'light',
      themes: {
        dark: {
            colors: {
                btnColor: '#305dc0',
                surfaceOpacity: '#ffffff1a',
            },
            opacity: '0.1'
        },
        light: {
            colors: {
                btnColor: '#AD8CA0',
                btnColorRed: '#ff5b5b',
                background: '#FAF0F6',
                surface: '#EBE3FA',
                surfaceOpacity: '#0000001a', 
            },
            opacity: '0.1'
          }
      }
    },
    options: {
        customProperties: true
      }
  })

// Я испольхую vue с vuetify. Как мне сделать и подключить файл с глобальными переменными scss
