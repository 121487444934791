<template>
      <v-main v-if="user">
        <v-card class="header d-flex justify-center w-100">
          <div class="content d-flex justify-space-between align-center">
            <div class="logo">
              <router-link to="/app" class="text-subtitle-2 font-weight-bold">Mind Map</router-link>
            </div>
            <div class="open-projects">

            </div>
            <div class="user d-flex align-center">
              <div class="d-flex align-center">
                <div class="avatar text-caption font-weight-bold d-flex justify-center align-center">
                  {{ user.email.substr(0, 1) }}
                </div>
                <div class="text-subtitle-2 font-weight-bold ml-3">{{ user.profile.name }} {{ user.profile.surname }}</div>
              </div>
              <div class="ml-6">
                <v-btn color="btnColor" variant="flat" class="font-weight-bold text-caption rounded-lg" size="small" @click="logout">Выйти</v-btn>
              </div>
            </div>
          </div>
        </v-card>
        <div class="w-100" style="height: calc(100vh - 50px);">
          <slot />
        </div>
      </v-main>
  </template>
  

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "CpLayout",
  computed: {
      user(){
        return this.$auth.user();
      },
  },
  mounted(){
    if(!this.$auth.check()) this.$router.push({ name: 'login' })
  },
  methods: {
    async logout(){
      try {
        await this.$auth.logout();
        this.$router.push('/login')
      } catch (error) {
        this.$router.push('/login')
      }
      
    }
  }
})

// socket.io-client vue использвание

</script>

<style lang="scss" scoped>

.header{
  box-shadow: none;
  height: 50px;
  z-index: 2;

  .content{
    max-width: 1100px;
    width: 95%;
  }

  .user{

    .avatar{
      width: 35px;
      height: 35px;
      background: rgba(var(--v-theme-btnColor), 1);
      border-radius: 100px;
      color: white;
    }
  }
}

</style>



